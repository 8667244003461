import { withTranslation } from 'react-i18next';
import './styles.scss';

const InfoModal = ({ data = {}, t }) => {
  const {
    title = '',
    description = '',
  } = data;

  return (
    <div className="info-modal">
      <p className="info-modal-title">{t(title)}</p>
      <p className="info-modal-description">{t(description)}</p>
    </div>
  );
};

export default withTranslation()(InfoModal);
