import types from './modalTypes';

export const setModalType = payload => ({
  type: types.OPEN_MODAL,
  payload: payload,
});

export const closeModal = () => ({
  type: types.CLOSE_MODAL,
});
export const setDataModal = payload => ({
  type: types.SET_DATA_MODAL,
  payload: payload,
});
