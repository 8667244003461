import { postError } from '../services/api/trackService/trackService';
export const selectActiveDay = week => {
    return week.reduce((acum, item, index) => {
        if (item.date === new Date().setHours(0, 0, 0, 0)) {
            return [...acum, { ...item, activeDay: index }];
        }
        return acum;
    }, '')[0];
};

export const normalizeData = week => {
    return week.map(item => {
        item.date = new Date(item.date).setHours(0, 0, 0, 0);
        return item;
    });
};

export const getDateNow = () => {
    return new Date().setHours(0, 0, 0, 0);
};

export const timeNormalizer = timeString => {
    const [minutes, seconds] = timeString.split(':');

    return `${minutes.length === 1 ? '0' : ''}${minutes}:${seconds.length === 1 ? '0' : ''}${seconds}`;
};

export const fromSecondsToMinutes = seconds => {
    const minutes = Math.trunc(seconds / 60);
    return timeNormalizer(`${minutes}:${seconds - minutes * 60}`);
};

export const lbsToKg = lbs => {
    return lbs * 0.45359237;
};

export const KgTolbs = kg => {
    return kg / 0.45359237;
};

export const feetTometer = ft => {
    return ft * 0.3048;
};

export const meterTofeet = mt => {
    return mt / 0.3048;
};

export const inchTocm = inch => {
    return inch * 2.54;
};

export const cmToinch = cm => {
    return cm / 2.54;
};
export const logError = (err, filename, functionName) => {
    postError({ errorStr: err?.toString(), fileName: filename, functionName })
        .then(resp => {})
        .catch(err => {
            console.log(err);
        });
};
export const getPlatform = () => {
    const platform = navigator.platform.toLowerCase();

    if (platform.includes('win')) return 'Windows';
    if (platform.includes('mac')) return 'Mac';
    if (platform.includes('linux')) return 'Linux';
    if (/iphone/.test(platform)) return 'iOS';
    if (/android/.test(platform)) return 'Android';

    return 'Unknown';
};