import { get, post } from '../apiService';

export const postTrack = body => {
    return post('/track', body);
};
export const postFitnessTest = body => {
    return post('/user/fitnesstest', body);
};
export const postError = body => {
    return post('/error', body);
};