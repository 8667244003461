import ReactPlayer from 'react-player/lazy';
import { useRef, useState } from 'react';
import screenfull from 'screenfull';
import muted from '../../../../assets/images/myPlan/moted.png';
import './styles.scss';
import { logError } from '../../../../utils/globalUtils';

const VideoModal = ({ onClose,url}) => {
    const player = useRef(null);
    const [play, setPlay] = useState(false);
    const [showControl, setShowControl] = useState(true);
    const [infoTime, setInfoTime] = useState({ seconds: 0, minute: 0 });
    const [isMuted, setIsMuted] = useState(false);
    const [infoTimeFill, setInfoTimeFull] = useState({ seconds: 0, minute: 0 });

    const _onProcess = ({ playedSeconds }) => {
        setInfoTime(({ minute, hour }) => ({
            seconds: Math.floor(minute ? playedSeconds - minute * 59 : playedSeconds),
            minute: Math.floor(playedSeconds / 59),
        }));
    };
    try {
        return (
            <div className="video-modal">
                <div className="video-modal-close" onClick={onClose} />
                <div className="video-modal__container">
                    <div
                        className={`${showControl && 'video-modal-full-scren'}`}
                        onClick={() => {
                            if (screenfull?.isEnabled) {
                                screenfull?.request(player.current.wrapper);
                            }
                        }}
                    />
                    <ReactPlayer
                        className="video-modal__container-player"
                        url={url}
                        playing={play}
                        light={false}
                        ref={player}
                        volume={1}
                        onProgress={_onProcess}
                        muted={isMuted}
                        onDuration={videoDuration => {
                            setInfoTimeFull({
                                seconds: videoDuration - Math.floor(videoDuration / 59) * 60,
                                minute: Math.floor(videoDuration / 59),
                            });
                        }}
                    />
                    <div
                        className={`${
                            showControl
                                ? !play
                                    ? 'video-modal_play video-modal_keyPlayStop'
                                    : 'video-modal_stop video-modal_keyPlayStop'
                                : 'video-modal_noshow'
                        }`}
                        onClick={() => setPlay(play => !play)}
                    />
                </div>
                <div className="video-modal__sub-container">
                    <div className="video-modal-info">
                        <p className="video-modal-info-cal">67 cal</p>
                        <p className="video-modal-info-time">
                            {infoTime.minute}:{infoTime.seconds}|{infoTimeFill.minute}:{infoTimeFill.seconds}
                        </p>
                        <p className="video-modal-info-name">Dave’s Plant</p>
                    </div>
                    <div className="video-modal-control-muted" onClick={() => setIsMuted(e => !e)}>
                        <img src={muted} alt={'audio'} />
                    </div>
                </div>
            </div>
        );
    } catch (error) {
        logError(error,'videoModal.js','VideoModal')
        return <div>Something went wrong</div>
    }
};
export default VideoModal;
