import { useEffect, useState } from 'react';
import imgClose from '../../../../assets/images/myPlan/imgClose.png';
import imgFreeChallenge from '../../../../assets/images/profile/back.png';
import { Button } from '../../../ui-core';
import './styles.scss';
import { useDispatch } from 'react-redux';
export const StartChallengeModal = ({ onClose, data, t }) => {
    const dispatch = useDispatch();

    const onSend = () => {
        data?.callback();
        onClose && onClose();
    };

    const closeHandler = () => {
        onClose && onClose();
    };

    return (
        <>
            <div className="free-default-modal">
                {/* <img onClick={closeHandler} src={imgClose} className="default-modal-close" alt={'close'} /> */}
                <div className="start-modal-body">
                    <div className="start-left-content">
                        <p className="start-modal-title">Welcome to the Challenge</p>
                        <p className="start-modal-content-1">
                            Your first 7 day period will start in 2 days. Please note that only one video per day will
                            count towards your 3 for the week. All videos must be played in its entirety, fast forwarded
                            videos will not be counted.
                        </p>
                        <Button className="free-popup-select-submit-not-now" onClick={onSend}>
                            START THE CHALLENGE
                        </Button>
                    </div>
                    <img src={imgFreeChallenge} className="right-content" width={350} />
                </div>
            </div>
        </>
    );
};
