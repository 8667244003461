import {
    forgotPasswordActions,
    forgotPasswordCodeActions,
    saveUserData,
    setLoadingStatus,
} from './authenticationActions';
import {
    getUserInfo,
    postForgotPassword,
    postForgotPasswordCode,
    postResetPassword,
    postSignIn,
    postSignUp,
    postUpdateTempToken,
} from '../../../services/api/authenticationService/authenticationService';
import {
    getLocalAccessToken,
    rememberLocalAuthTokens,
} from '../../../services/api/tokenService';
import routerBook from '../../../routes/routerBook';
import { getUserSubscription } from '../../../services/api/paymentService/paymentService';

import { errorHandler } from '../../../components/toastify/redux/notifyOperations';

export const signUpUser = (data, onSuccess, onError) => {
    return dispatch => {
        dispatch(setLoadingStatus(true));

        postSignUp(data)
            .then(({ auth, user }) => {
                let dateEnd = new Date(new Date().getTime() + Number.parseInt(auth.expiresIn));

                rememberLocalAuthTokens({ ...auth, dateEnd });

                /*window.location.reload()*/

                onSuccess && onSuccess();
            })
            .catch(error => {
                onError && onError(error);
            })
            .finally(() => dispatch(setLoadingStatus(false)));
    };
};

export const authorizeUser = (credentials, onSuccess, onError) => {
    return dispatch => {
        dispatch(setLoadingStatus(true));

        postSignIn(credentials)
            .then(({ user, quizPassed, auth, paymentInformation, apple }) => {
                rememberLocalAuthTokens(auth);

                getUserSubscription()
                    .then(data => {
                        dispatch(
                            saveUserData({
                                ...user,
                                data,
                                quizPassed,
                                paymentInformation,
                                isUserSubscribed: true,
                                apple,
                            }),
                        );
                    })
                    .catch(() => dispatch(saveUserData({ ...user, quizPassed, paymentInformation })));

                onSuccess && onSuccess();
            })
            .catch(error => {
                onError && onError(error);
            })
            .finally(() => dispatch(setLoadingStatus(false)));
    };
};

export const requestUserData = (onSuccess, onError) => {
    return async dispatch => {
        dispatch(setLoadingStatus(true));

        let userData = null

        try {
            const { user, userAnswers, paymentInformation, apple } = await getUserInfo();
            userData = { ...user, userAnswers, paymentInformation, apple };
        } catch (error) {
            if (getLocalAccessToken()) {
                localStorage.clear();
                window.location.reload();
            }
            onError && onError(error);
        }

        try {
            const userSubscription = await getUserSubscription()
            userData.isUserSubscribed = true;
            userData.data = userSubscription;
        } catch (error) {
            console.log('Error getting subscription', error);
        }

        dispatch(saveUserData(userData))
        dispatch(setLoadingStatus(false))
        onSuccess && onSuccess()
    };
};

export const logoutUser = (onSuccess, onError) => {
    return dispatch => {
        dispatch(setLoadingStatus(true));
        sessionStorage.clear();
        localStorage.clear();
        window.location.reload();
    };
};

export const requestForgotUserPassword = (data, navigate, onSuccess, onError) => {
    return dispatch => {
        dispatch(setLoadingStatus(true));

        postForgotPassword(data)
            .then(({ id, code }) => {
                dispatch(forgotPasswordActions({ id, code }));
                onSuccess && onSuccess();
                navigate(routerBook.checkCode.replace(':id', id));
            })
            .catch(error => {
                onError && onError(error);
            })
            .finally(() => dispatch(setLoadingStatus(false)));
    };
};

export const requestForgotUserPasswordCode = (data, navigate, onSuccess, onError) => {
    return dispatch => {
        dispatch(setLoadingStatus(true));

        postForgotPasswordCode(data)
            .then(() => {
                dispatch(forgotPasswordCodeActions({ ...data }));
                onSuccess && onSuccess();
                navigate(routerBook.resetPassword.replace(':id', data.id));
            })
            .catch(error => {
                onError && onError(error);
            })
            .finally(() => dispatch(setLoadingStatus(false)));
    };
};

export const requestResetUserPassword = (data, navigate, onSuccess, onError) => {
    return dispatch => {
        postResetPassword(data)
            .then(() => {
                onSuccess && onSuccess();
                navigate(routerBook.login);
            })
            .catch(error => {
                onError && onError(error);
            })
            .finally(() => dispatch(setLoadingStatus(false)));
    };
};

export const verifyTokenQuizAndPayment = (refreshToken) => {
    return async dispatch => {
        let userData = {};

        try {
            const response = await postUpdateTempToken({ refreshToken })
            rememberLocalAuthTokens(response.auth);
            userData = { ...response.user };
        } catch (e) {
            dispatch(errorHandler(e))
            return;
        }

        try {
            const response = await getUserInfo()
            userData.userAnswers = response.userAnswers;
            userData.paymentInformation = response.paymentInformation;
        } catch (e) {
            dispatch(errorHandler(e))
            return;
        }

        try {
            const response = await getUserSubscription()
            userData.data = response;
            userData.isUserSubscribed = true;
        } catch (e) {
            dispatch(errorHandler(e))
        }

        dispatch(saveUserData(userData))
    };
};
