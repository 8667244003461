import types from './notifyTypes';

const initialState = {
  isVisible: false,
  type: '',
  title: '',
  content: '',
};

export const notifyReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case types.SET_NOTIFY_STATUS:
      return payload;
    default:
      return state;
  }
};
