import React, {useMemo} from 'react';
import {withTranslation} from 'react-i18next';
import { NavLink, useLocation, useParams } from 'react-router-dom';

import routerBook from '../../../routes/routerBook';
import logoutImage from '../../../assets/images/profile/logout.svg';

import './styles.scss';

const Footer = ({t, openContactsUs, logout}) => {
    const {pathname} = useLocation();
    const {id} = useParams()

    const {
        howItWorks,
        question,
        login,
        registration,
        paymentPage,
        forgotPassword,
        resetPassword,
        checkCode,
        main
    } = routerBook;

    const smallFooter = [howItWorks]

    const disabledFooter = [question, main];

    const transparentFooter = [login, registration, forgotPassword, resetPassword, checkCode].map(el => el ? el.replace(':id', id) : el);

    const mobileDisabledFooter = [login, registration, forgotPassword, resetPassword, checkCode].map(el => el ? el.replace(':id', id) : el);

    const isPaymentPage = paymentPage === pathname;

    if (smallFooter.includes(pathname)) {
        return (
            <footer className="footer-secondary">

                <NavLink to={routerBook.contact}>{t('footer_how_it_works')}</NavLink>

                <NavLink to={routerBook.contact}>{t('footer_contact')}</NavLink>

                <NavLink to={routerBook.privacyPolicy}>{t('footer_privacy_policy')}</NavLink>

                <NavLink to={routerBook.termsOfService}>{t('footer_terms_of_service')}</NavLink>

            </footer>
        )
    }

    if (disabledFooter.includes(pathname)) return null

    return (
        <footer className={
            `footer-primary ${transparentFooter.includes(pathname) ?
                'footer-transparent' : ''} ${mobileDisabledFooter.includes(pathname) ?
            'footer-mobile-disable' : ''}`
        }>

            <span className="footer-primary__left">{t('footer_logo')}</span>

            <div className="footer-primary__right">

                {isPaymentPage && (
                    <NavLink onClick={logout} className="footer-primary__right__logout">
                        {t('header_tertiary_logaut')}
                        <img src={logoutImage} alt="logOut" />
                    </NavLink>
                )}

                <NavLink to={routerBook.contact}>{t('footer_contact')}</NavLink>

                <NavLink to={routerBook.termsOfService}>{t('footer_terms_of_service')}</NavLink>

                <NavLink to={routerBook.privacyPolicy} className="footer-primary__right__privacy-policy">{t('footer_privacy_policy')}</NavLink>

            </div>

        </footer>
    );
};

export default withTranslation()(Footer);
