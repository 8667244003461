import { useEffect, useState } from 'react';
import imgClose from '../../../../assets/images/myPlan/imgClose.png';
import { Button } from '../../../ui-core';
import './styles.scss';

export const RatePopUpForTest = ({ onClose, data, t }) => {
    const [showHint, setShowHint] = useState(false);
    const [difficulty, setDifficulty] = useState(0);

    const onSend = val => {
        onClose && onClose();
        data.callback(val);
    };

    const closeHandler = () => {
        onClose && onClose();
    };
    // setUsersRate.bind(null, 5)
    return (
        <>
            {/* ========= rate pop up start =========== */}
            <div className="default-modal">
                <img onClick={closeHandler} src={imgClose} className="default-modal-close" alt={'close'} />
                <p className="default-modal-title">How was your workout?</p>
                <p className="popup-select-description">
                    Please rate the difficulty from 1-5 <span>(5 being the hardest).</span>
                </p>
                <div className="popup-select-container">
                    <div
                        className={`popup-select-container-hint${showHint.ratePopup ? ' hint rate-popup__hint' : ''}`}
                    />
                    <Button
                        onClick={() => onSend(1)}
                        className={`popup-select-rate${difficulty === 1 ? ' popup-selected-rate' : ''}`}
                    >
                        1
                    </Button>
                    <Button
                        onClick={() => onSend(2)}
                        className={`popup-select-rate${difficulty === 2 ? ' popup-selected-rate' : ''}`}
                    >
                        2
                    </Button>
                    <Button
                        onClick={() => onSend(3)}
                        className={`popup-select-rate${difficulty === 3 ? ' popup-selected-rate' : ''}`}
                    >
                        3
                    </Button>
                    <Button
                        onClick={() => onSend(4)}
                        className={`popup-select-rate${difficulty === 4 ? ' popup-selected-rate' : ''}`}
                    >
                        4
                    </Button>
                    <Button
                        onClick={() => onSend(5)}
                        className={`popup-select-rate${difficulty === 5 ? ' popup-selected-rate' : ''}`}
                    >
                        5
                    </Button>
                </div>
            </div>
            {/* ========= rate pop up end =========== */}
        </>
    );
};
