const types = {
  SET_QUESTION_LIST: 'SET_QUESTION_LIST',
  SET_ACTIVE_QUESTION: 'SET_ACTIVE_QUESTION',
  FINISH_LOADER_QUESTION: 'FINISH_UPLOAD_QUESTION',
  START_LOADER_QUESTION: 'START_LOADER_QUESTION',
  SET_PERCENT_QUESTION: 'SET_PERCENT_QUESTION',
  SET_ANSWER: 'SET_ANSWER',
};

export default types;
