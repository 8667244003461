import { Navigate, createBrowserRouter } from 'react-router-dom';

import { lazy, Suspense } from 'react';

import Layout from '../components/layout/layout';
import { Loader } from '../components/loader/loader';
import { useSelector } from 'react-redux';

const Landing = lazy(() => import('../pages/landing/landing'));
const Login = lazy(() => import('../pages/authentication/component/login/login'));
const Registration = lazy(() => import('../pages/authentication/component/registration/registration'));
const Verify = lazy(() => import('../pages/authentication/component/verify/verify'));
const CheckCode = lazy(() => import('../pages/authentication/component/checkCode/checkCode'));
const ResetPassword = lazy(() => import('../pages/authentication/component/resetPassword/resetPassword'));
const ForgotPassword = lazy(() => import('../pages/authentication/component/forgotPassword/forgotPassword'));
const Contact = lazy(() => import('../pages/contact/contact'));
const PrivacyPolicy = lazy(() => import('../pages/privacy-policy/PrivacyPolicy'));
const TermsOfService = lazy(() => import('../pages/termsOfService/TermsOfService'));
const NoSubscription = lazy(() => import('../pages/noSubscription/noSubscription'));
const Profile = lazy(() => import('../pages/profile/profile'));
const PaymentPage = lazy(() => import('../pages/paymentPage/paymentPage'));
const ViewPlan = lazy(() => import('../pages/viewPlan/viewPlan'));
const Workouts = lazy(() => import('../pages/workouts/workouts'));
const Challenges = lazy(() => import('../pages/challenges/challenges'));
const ChallengesItem = lazy(() => import('../pages/challengesItem/challengesItem'));
const Saved = lazy(() => import('../pages/saved/saved'));
const Question = lazy(() => import('../pages/question/question'));
const MyPlan = lazy(() => import('../pages/myPlan/index'));

const UpdatePlan = lazy(() => import('../pages/updatePlan'));
export const withLazyComponent = lazyComponent => {
    return <Suspense fallback={Loader}>{lazyComponent}</Suspense>;
};


export const routes = [
    {
        path: '/',
        element: <Layout />,
        children: [
            {
                path: 'how-it-works',
                element: withLazyComponent(<Landing />),
            },
            {
                path: 'login',
                element: withLazyComponent(<Login />),
            },
            {
                path: 'registration',
                element: withLazyComponent(<Registration />),
            },
            {
                path: 'sign-up',
                element: <Navigate to={'/registration'} state={{ isfreeVersion: true }} />,
            },
            {
                path: 'verify',
                element: withLazyComponent(<Verify />),
            },
            {
                path: 'forgot-password',
                children: [
                    {
                        path: 'check-code/:id',
                        element: withLazyComponent(<CheckCode />),
                    },
                    {
                        path: 'reset/:id',
                        element: withLazyComponent(<ResetPassword />),
                    },
                    {
                        index: true,
                        element: withLazyComponent(<ForgotPassword />),
                    },
                    {
                        path: '*',
                        element: <Navigate to={'/forgot-password'} replace={true} />,
                    },
                ],
            },
            {
                path: 'contact',
                element: withLazyComponent(<Contact />),
            },
            {
                path: 'privacy-policy',
                element: withLazyComponent(<PrivacyPolicy />),
            },
            {
                path: 'terms-of-service',
                element: withLazyComponent(<TermsOfService />),
            },
            {
                path: 'dashboard',
                children: [
                    {
                        path: 'no-sub',
                        element: withLazyComponent(<NoSubscription />),
                    },
                    {
                        path: 'profile',
                        children: [
                            {
                                path: 'about-me',
                                element: withLazyComponent(<Profile />),
                            },
                            {
                                path: 'account',
                                element: withLazyComponent(<Profile />),
                            },
                            {
                                path: 'contact-us',
                                element: withLazyComponent(<Profile />),
                            },
                            {
                                path: 'device',
                                element: withLazyComponent(<Profile />),
                            },
                            {
                                index: true,
                                element: withLazyComponent(<Profile />),
                            },
                            {
                                path: '*',
                                element: <Navigate to={'/dashboard/profile'} replace={true} />,
                            },
                        ],
                    },
                    {
                        path: 'payment-page',
                        element: withLazyComponent(<PaymentPage />),
                    },
                    {
                        path: 'plan-view',
                        element: withLazyComponent(<ViewPlan />),
                    },
                    {
                        path: 'workouts',
                        children: [
                            {
                                path: ':bodyPart',
                                element: withLazyComponent(<Workouts />),
                            },
                            {
                                index: true,
                                element: withLazyComponent(<Workouts />),
                            },
                        ],
                    },
                    {
                        path: 'challenges',
                        element: withLazyComponent(<Challenges />),
                    },
                    {
                        path: 'challenge',
                        element: withLazyComponent(<ChallengesItem />),
                    },
                    {
                        path: 'saved',
                        element: withLazyComponent(<Saved />),
                    },
                    {
                        path: 'update-plan',
                        element: withLazyComponent(<UpdatePlan />),
                    },
                    {
                        path: 'question',
                        element: withLazyComponent(<Question />),
                    },
                    {
                        index: true,
                        element: withLazyComponent(<MyPlan />),
                    },
                    {
                        path: '*',
                        element: <Navigate to={'/dashboard'} />,
                    },
                ],
            },
            {
                index: true,
                element: withLazyComponent(<Landing />),
            },
            {
                path: '*',
                element: <Navigate to={'/'} />,
            },
        ],
    },
];

export default createBrowserRouter(routes);
