import {
  checkOnboarding,
  closeOnboarding,
  setOnboardingList,
  setOnboardingType,
  setStepOnboarding,
} from './onboardingActions';
import { onboardingHints } from '../config';

export const openOnboarding =
  ({ type }) =>
  dispatch => {
    dispatch(setOnboardingType(type));
    dispatch(nextStep());
  };

export const saveItems = (type, element) => dispatch => {
  const hintsList = onboardingHints.map(item => {
    if (item.type === type) {
      item.elemen = element;
      return item;
    }
    return item;
  });
  dispatch(setOnboardingList(hintsList));
};

export const setClassName = (hintsList, activeIndex) => dispatch => {
  if (hintsList.length > activeIndex && hintsList[activeIndex].elemen) {
    hintsList[activeIndex].elemen.classList.add('hint-class');
    hintsList[activeIndex].elemen.scrollIntoView({block: "nearest"});
    document.body.style.overflow = "hidden"
    if (hintsList[activeIndex].id === '3' || hintsList[activeIndex].id === '4' ) {
      hintsList[activeIndex].elemen.scrollIntoView({block: "center"});
    }
    if (activeIndex) {
      hintsList[--activeIndex].elemen?.classList.remove('hint-class');
    }
  } else {
      hintsList[--activeIndex].elemen.classList.remove('hint-class');
      hintsList[activeIndex].elemen.scrollIntoView(false);
      document.body.style.overflow = "auto"
      dispatch(closeOnboarding());
      dispatch(checkOnboarding(false));
  }
};

export const nextStep = () => (dispatch, getState) => {
  const { onboardingList } = getState().onboardingReducer;
  const { activeStep } = getState().onboardingReducer;
  dispatch(setStepOnboarding(activeStep + 1));
  dispatch(setClassName(onboardingList, activeStep));
};
