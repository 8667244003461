import React, { useMemo } from 'react';
import { Outlet, useLocation, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import cn from 'classnames';

import Onboarding from '../onboarding/onboarding';
import Toastify from '../toastify/toastify';
import Modal from '../modal/modal';

import HeaderNavigationPanel from './headerNavigationPanel/headerNavigationPanel';
import Footer from '../layout/footer/footer';

import routerBook from '../../routes/routerBook';
import { logoutUser } from '../../pages/authentication/redux/authenticationOperations';

import './styles.scss';

const Layout = () => {
    const { pathname } = useLocation();
    const { id } = useParams();

    const { data } = useSelector(store => store.authState);
    const dispatch = useDispatch();

    const authBackground = [
        routerBook.login,
        routerBook.registration,
        routerBook.forgotPassword,
        routerBook.resetPassword,
        routerBook.checkCode,
    ];

    const isAuthBackground = useMemo(() => {
        return authBackground.map(el => el.replace(':id', id)).includes(pathname);
    }, [pathname, authBackground]);

    const logout = () => dispatch(logoutUser());

    const isQuestionPage = pathname === routerBook.question || pathname === routerBook.updatePlan;
    const hasHeaderAndFooter = !data || data?.isUserSubscribed || isQuestionPage || pathname === routerBook.paymentPage;

    return (
        <>
            {hasHeaderAndFooter && <HeaderNavigationPanel />}
            <main
                className={cn('main', {
                    homeClass: isQuestionPage,
                    authMain: isAuthBackground,
                })}
            >
                <Outlet />
            </main>
            {hasHeaderAndFooter && <Footer logout={logout} />}

            <Modal />
            <Onboarding />
            <Toastify />
        </>
    );
};
export default Layout;
