import { useEffect, useState } from 'react';
import imgClose from '../../../../assets/images/myPlan/imgClose.png';
import imgFreeChallenge from '../../../../assets/images/newImages/Eileen_Intro.jpg';
import { Button } from '../../../ui-core';
import './styles.scss';
import { useDispatch } from 'react-redux';
export const FitTestModal = ({ onClose, data, t }) => {
    const dispatch = useDispatch();

    const onSend = () => {
        data?.callback();
        onClose && onClose();
    };

    const closeHandler = () => {
        data?.close();
        onClose && onClose();
    };

    return (
        <>
            <div className="free-default-modal">
                {/* <img onClick={closeHandler} src={imgClose} className="default-modal-close" alt={'close'} /> */}
                <div className="start-modal-body">
                    <div className="start-left-content">
                        <p className="start-modal-title">Welcome to Formulaic!</p>
                        <p className="start-modal-content-1">
                            Would you like to take a short 10-minute fitness test? This test will help us determine your fitness level, allowing us to tailor your workouts to suit your needs.
                        </p>
                        <div className='modal-actions'>
                            <Button className="free-popup-select-submit-not-now" onClick={onSend}>
                                Start Test
                            </Button>
                            <Button className="free-popup-select-submit-not-now" onClick={closeHandler}>
                                Maybe Later
                            </Button>
                        </div>

                    </div>
                    <img src={imgFreeChallenge} className="right-content" width={350} />
                </div>
            </div>
        </>
    );
};
