import headerFooter from './headerFooter.json';
import myPlan from './myPlan.json';
import auth from './auth.json';
import paymentPage from './paymentPage.json';
import modal from './modal.json';
import profile from './profile.json';

const translation = {
  ...headerFooter,
  ...myPlan,
  ...auth,
  ...paymentPage,
  ...modal,
  ...profile,
};
export default translation;
