import {authTypes} from "./authenticationTypes";

export const saveUserData = (userData) => {
    return {type: authTypes.SAVE_PROFILE_DATA, payload: userData}
}

export const removeUserData = () => {
    return {type: authTypes.REMOVE_PROFILE_DATA}
}

export const updateUserData = (newUserData) => {
    return {type: authTypes.UPDATE_PROFILE_DATA, payload: newUserData}
}

export const setLoadingStatus = (loadingStatus) => {
    return {type: authTypes.SET_PROFILE_LOADING_STATUS, payload: loadingStatus}
}

export const forgotPasswordActions = data => {
    return {type: authTypes.FORGOT_PASSWORD_SUCCESS, payload: data}
}
export const forgotPasswordCodeActions = data => {
    return {type: authTypes.FORGOT_PASSWORD_CODE_SUCCESS, payload: data}
}
