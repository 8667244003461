import {useDispatch, useSelector} from 'react-redux';
import {toast, ToastContainer} from 'react-toastify';
import {setNotifyStatus} from './redux/notifyActions';
import './styles.scss';
import 'react-toastify/dist/ReactToastify.css';

const Toastify = () => {
    const {title, type} = useSelector(state => state.notify);
    const dispatch = useDispatch();
    const Notification = () => {
        return <h4 style={{fontWeight: 400}}>{title.message || title}</h4>;
    };
    if (type === 'error') {
        toast.error(<Notification/>);
        dispatch(
            setNotifyStatus({isVisible: false, type: '', title: '', content: ''}),
        );
    } else if (type === 'success') {
        toast.success(<Notification/>)
        dispatch(
            setNotifyStatus({isVisible: false, type: '', title: '', content: ''}),
        );
    }
    return (
        <ToastContainer
            autoClose={5000}
            position="bottom-right"
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss={false}
            draggable
            limit={6}
            pauseOnHover
        />
    );
};
export default Toastify;
