import React from 'react';

import passwordIcon from '../../../assets/images/input/password.svg';
import emailIcon from '../../../assets/images/input/email.svg';
import userIcon from '../../../assets/images/input/user.svg';
import './styles.scss';

const Input = ({
                   options = {},
                   inputChangeOptions = {},
                   errors = false,
                   onHandleClick,
               }) => {
    const {
        styleType = '',
        labelText = '',
        placeholderText = '',
        descriptionText = '',
        type = 'text',
        isDisabled = false,
        placeholderIcon = '',
        autocomplete = '',
        className = '',
    } = options;

    const handleChangeInputIcon = () => {
        switch (placeholderIcon) {
            case 'email':
                return emailIcon;
            case 'password':
                return passwordIcon;
            case 'user':
                return userIcon;

            default:
                break;
        }
    };
    return (
        <div className='custom-input__container'>
            <label className={`custom-input__label`}>{labelText}</label>
            <div className={`custom-input-container ${className}`}>
                <input
                    onClick={onHandleClick}
                    {...inputChangeOptions}
                    placeholder={placeholderText}
                    disabled={isDisabled}
                    style={{ paddingLeft: placeholderIcon && '50px' }}
                    className={`custom-input custom-input__single-item ${
                        styleType ? 'custom-input-' + styleType : ''
                    }`}
                    type={type}
                    autoComplete={autocomplete}
                />
                {placeholderIcon && (
                    <img
                        className='custom-input__icon'
                        src={handleChangeInputIcon()}
                        alt='icon'
                    />
                )}
                {descriptionText && (
                    <p className='field__description'>{descriptionText}</p>
                )}
                {errors && <span className='errors'>{errors}</span>}
            </div>
        </div>
    );
};

export default Input;
