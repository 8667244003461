import types from './modalTypes';

const initialState = {
    dataModal: {},
    typeModal: 'DEFAULT_MODAL',
    statusModal: false,
};

const modalReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case types.OPEN_MODAL: {
            return { ...state, typeModal: payload, statusModal: true };
        }
        case types.CLOSE_MODAL: {
            return {
                ...state,
                typeModal: 'DEFAULT_MODAL',
                statusModal: false,
                dataModal: {},
            };
        }
        case types.SET_DATA_MODAL: {
            return { ...state, dataModal: payload };
        }

        default:
            return state;
    }
};

export default modalReducer;
